@mixin popover {
	display: none;
	position: absolute;
	top: 55px;
	// top: -310px;
	right: 0px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
	// z-index: 110;
	z-index: 20000;
	max-height: 320px;
	min-width: 150px;
	max-width: 100%;
	overflow-y: auto;
}

.popover {
	@include popover;
}
.fullWidth {
	left: 0;
}
.open {
	display: block;
}

// Positioning the popover
.t_60__r_0 {
	top: 60px;
	right: 0px;
}
.t_n190__r_0 {
	top: -190px;
	right: 0px;
}

.t_n260__r_0 {
	top: -260px;
	right: 0px;
}
.t_n360__r_0 {
	top: -330px;
	right: 0px;
}
